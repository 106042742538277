import React from "react"
import Meta from "../components/meta"
import Navbar from "../components/navbar"
import Footer from "../components/footer"
import Title from "../components/title"
import Back from "../components/back"

export default function Home() {
  return (
    <>
      <Meta title="RFID / IoT を利用したシステム" description="数々の RFID を用いたプロジェクトを推進しており、豊富な経験を有しております。貴社の課題をクリアにし、解決のための最適なご提案をさせていただきます。お気軽にご相談ください。" />
      <div className="flex flex-col h-screen">
        <header>
          <Navbar hasContact={false} />
        </header>
        <main className="mt-14 reveal fade-bottom">
          <div className="container max-w-5xl mx-auto py-4 px-4">
            <Title title="RFID / IoT を利用したシステム" />

            <div className="bg-white rounded-lg mb-4 flex flex-wrap">
              <div className="w-full p-6">
                <h3 className="card-heading text-xl md:text-3xl font-bold leading-none">
                  RFID とは？
                </h3>
                <p className="mt-4 md:mt-8 text-gray-600 leading-7">
                  RFID、一度は耳にしたことがあるかも知れません。導入実績の記事なども散見されますが、まだまだ一般的なシステムにはなっていません。そんな RFID についておさらいしましょう。
                </p>

                <blockquote className="mt-4 md:mt-8 mx-4">RFID（英: <span><u>r</u>adio <u>f</u>requency <u>id</u>entifier</span>）とは、ID情報を埋め込んだRFタグから、電磁界や電波などを用いた近距離（周波数帯によって数cm～数m）の無線通信によって情報をやりとりするもの、および技術全般を指す。
                  (出典: <a href="https://ja.wikipedia.org/wiki/RFID">Wikipedia</a>)
                </blockquote>

                <p className="mt-4 md:mt-8 text-gray-600 leading-7">
                  電波を用いてデータを非接触で読み書きする技術ということです。バーコードでの読み取りと比較してみましょう。
                </p>

                <ul className="mt-4 md:mt-8">
                  <li>電波が届く範囲で複数枚を一気に読み取ることができる（バーコードでは一枚ごと）</li>
                  <li>汚れに強い（バーコードは一部が汚れてしまうと読み取れなくなる）</li>
                  <li>電波なので、例えば段ボールなどは透過する（バーコードでは箱から出して読み取る必要がある）</li>
                </ul>

                <p className="mt-4 md:mt-8 text-gray-600 leading-7">
                  棚卸し作業や点検、在庫管理など業務の大幅な改善が見込めることがお分りいただけるかと思います。
                </p>

                <h3 className="mt-4 md:mt-8 card-heading text-xl md:text-3xl font-bold leading-none">
                  RFID のイメージ
                </h3>
                <div className="mt-4 md:mt-8 text-gray-600 leading-7">
                  <span className="text-lg text-marker-light">非常に高価なのでは？</span><br />
                  <p className="mb-2 mx-4">一般に管理対象物に貼り付けする必要のあるタグが高価と言われます。しかし 2017 年には経済産業省による宣言<a href="http://www.meti.go.jp/press/2017/04/20170418005/20170418005.html">「コンビニ電子タグ1000億枚宣言」を策定した</a>こともあり、価格は一枚一円以下へ向けて業界全体が動いております。
                  </p>

                  <span className="text-lg text-marker-light">大規模でなければ意味がないのでは？</span><br />
                  <p className="mb-2 mx-4">大規模であるほど効果は実感しやすいですが、例えば工具のように一式が抜け漏れなく揃っていることを明確にしたいというようなシチュエーションもあるかと思われます。人手不足の現代でこそ、少しでも自動化による効率化を行いましょう。
                  </p>

                  <span className="text-lg text-marker-light">セキュリティが心配</span><br />
                  <p className="mx-4">情報の改ざんや不正アクセスへの対策として認証や暗号化技術によるセキュリティ確保が可能です。
                  </p>
                </div>

                <h3 className="mt-4 md:mt-8 card-heading text-xl md:text-3xl font-bold leading-none">
                  貴社の課題をお聞かせください
                </h3>
                <p className="mt-4 md:mt-8 text-gray-600 leading-7">
                  RFID は全ての業務を必ず改善できるという魔法のツールではありません。また、特有のハードウェアや概念の知識が必要なため一般の技術者が容易に情報収集や経験を詰める分野でもありません。
                  <br />
                  しかし株式会社ベルクラフトでは数々の RFID を用いたプロジェクトを推進しており、豊富な経験を有しております。貴社の課題をクリアにし、解決のための最適なご提案をさせていただきます。お気軽にご相談ください。
                </p>

                <Back />
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </>
  )
}
